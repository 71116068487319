<template>
  <el-table
    style="width: 100%;"
    empty-text="Keine Ergebnisse"
    stripe
    :data="events"
  >
    <el-table-column prop="name" label="Name">
      <template #default="scope">
        <el-link
          v-if="scope.row.status === 'approved'"
          type="primary"
          :href="scope.row.sharing_url"
          target="_blank"
        >
          {{ scope.row.name }}
        </el-link>
        <span v-else>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column
      v-if="viewAs === 'region'"
      prop="organization.name"
      label="Veranstalter"
    ></el-table-column>
    <el-table-column prop="status" label="Status" width="200">
      <template #default="scope">
        <el-tag
          size="small"
          :type="statusDefaults[viewAs][scope.row.status].type"
        >
          {{ statusDefaults[viewAs][scope.row.status].text }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      v-if="mode !== 'past'"
      prop="upcoming_dates"
      label="Kommende Termine"
      width="200"
    >
      <template #default="scope">
        <el-tag
          v-for="date in scope.row.upcoming_dates"
          :key="date.id"
          size="small"
          class="date-tag"
        >
          {{ format(parseISO(date.starts_at), "dd.LL.yyyy") }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Aktionen" width="350">
      <template #default="scope">
        <div v-if="!scope.row.source_event_id">
          <el-button
            v-if="!(mode === 'past' && viewAs === 'organization')"
            icon="fal fa-edit"
            size="mini"
            @click="handleEdit(scope.row)"
          >
            Bearbeiten
          </el-button>
          <el-button size="mini" @click="cloneEvent(scope.row)">
            Duplizieren
          </el-button>
          <delete-button
            v-slot="slotProps"
            :on-delete="deleteEvent"
            subject="Veranstaltung"
          >
            <el-button
              size="mini"
              icon="fal fa-trash-alt"
              type="danger"
              @click="slotProps.handleDelete(scope.row)"
            >
              Löschen
            </el-button>
          </delete-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { format, parseISO } from "date-fns"
import DeleteButton from "@/components/DeleteButton.js"
import RegionEventsRepository from "@/repositories/events_repository.js"
import OrganizationEventsRepository from "@/repositories/organization/events_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    events: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: null
    },
    viewAs: {
      type: String,
      default: "region"
    }
  },
  data() {
    return {
      format,
      parseISO,
      statusDefaults: {
        region: {
          submitted: {
            type: "warning",
            text: "Neu eingereicht"
          },
          resubmitted: {
            type: "warning",
            text: "Vom Anbieter überarbeitet"
          },
          rejected: {
            type: "danger",
            text: "Abgelehnt"
          },
          approved: {
            type: "success",
            text: "Veröffentlicht"
          },
          needs_edit: {
            type: "info",
            text: "Muss überarbeitet werden"
          }
        },
        organization: {
          submitted: {
            type: "info",
            text: "Wird von der Redaktion geprüft"
          },
          resubmitted: {
            type: "info",
            text: "Wird von der Redaktion geprüft"
          },
          rejected: {
            type: "danger",
            text: "Abgelehnt"
          },
          approved: {
            type: "success",
            text: "Veröffentlicht"
          },
          needs_edit: {
            type: "warning",
            text: "Muss überarbeitet werden"
          }
        }
      }
    }
  },
  methods: {
    handleEdit(event) {
      this.$router.push({
        name: "EditEventPage",
        params: { id: event.id },
        query: {
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    async cloneEvent(event) {
      let originalEvent = null

      try {
        if (this.viewAs === "region") {
          originalEvent = await RegionEventsRepository.get(event.id)
        } else {
          originalEvent = await OrganizationEventsRepository.get(event.id)
        }

        originalEvent.upcoming_dates = []
        originalEvent.later_entry = false
        originalEvent.id = null

        this.$router.push({
          name: "NewEventPage",
          params: { cloneFrom: originalEvent }
        })
      } catch (error) {
        this.handleApiError(error)
      }
    },
    async deleteEvent(event) {
      if (this.viewAs === "region") {
        await RegionEventsRepository.destroy(event.id)
      } else {
        await OrganizationEventsRepository.destroy(event.id)
      }
      this.$emit("on-delete")
    }
  }
}
</script>

<style lang="scss">
.date-tag {
  margin-right: 3px;
  margin-bottom: 3px;
}
</style>
